import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import firebase from "firebase/compat/app"
import {getMessaging,onMessage} from "firebase/messaging";


// const firebaseConfig = {
//   apiKey: "xxxxxxxx",
//   authDomain: "xxxxxxxx",
//   projectId: "xxxxxxxx",
//   storageBucket: "xxxxxxxx",
//   messagingSenderId: "xxxxxxxx",
//   appId: "xxxxxxxx",
//   measurementId: "xxxxxxxx"
// };
const firebaseConfig = {
  apiKey: "AIzaSyBcV88Z2PuWWkeTNaVc-tuOToou2Z8UGoQ",
  authDomain: "goldenbells-2472b.firebaseapp.com",
  projectId: "goldenbells-2472b",
  storageBucket: "goldenbells-2472b.appspot.com",
  messagingSenderId: "761031347589",
  appId: "1:761031347589:web:135e33b8352cec3b96c8ec",
  measurementId: "G-BQ33SHPBRS"
};


    // eslint-disable-next-line
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
  } else {
      firebase.app(); // if already initialized, use that one
  }

  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app)
  // onMessage(messaging, (payload) => {
  //   console.log("payload", payload)

  // });

const authentication = getAuth();


export {app, authentication,messaging};
